export const environment = {
  production: true, google_maps_key: "AIzaSyB9PC-QkIrgcsJBX9M5OBhnCCOpw2JDhcc",
  uploadUrl: 'https://buslane-upload-storage-sqdyjvm7uq-uc.a.run.app',
  apiUrl: "https://python-api.buslane.com",
  apiPaymentUrl: "https://dev-node-api-sqdyjvm7uq-uc.a.run.app",
  apiRoute: 'https://node-api.buslane.com',
  operatorID: '7b4cf207-c69b-48fa-a9f0-eb25969fcedf',
  APP_ID: '4u9FY264',
  APP_SECRET: 'sk_live_l9MQPRd5ozuRmzJj8FfGkVAC7kKqP9X8',
  STRIPE_ID: 'ca_JgDn2kgIKJe47gZDg4wnhuANhj5PL2IQ',
  OP_URL: 'https://op.buslane.com',
  conversationIDSuffix: ''


};
